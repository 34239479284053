import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { FormField } from '../page/components'
import { filterByStartDateAndEndDate } from '../../utils/helpers'
import Radio from '@material-ui/core/Radio'
import RadioGroup from '@material-ui/core/RadioGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormControl from '@material-ui/core/FormControl'
import moment from 'moment'

const SetupIntakeShilumniCourses = ({ firebase, values, handleDataChange }) => {
  const { campus, year, courseType } = values

  const [errors, setErrors] = useState(null)
  const [currIntake, setCurrIntake] = useState(null)
  const [intakes, setIntakes] = useState(null)
  const [fetching, setFetching] = useState(false)
  const [fetched, setFetched] = useState(false)

  const endMonths = courseType === 'Full Time' ? `03-01` : `12-31`

  const startDate =
    year === '2005'
      ? new Date(`${1997 - 1}-12-31`)
      : new Date(`${parseInt(year) - 2}-12-31`)
  const endDate = new Date(`${parseInt(year) + 1}-${endMonths}`)

  const fetchIntakes = async () => {
    setErrors(false)
    setFetching(true)
    setFetched(false)
    setCurrIntake(null)
    handleDataChange('intake', { id: '' })

    const startTs = firebase.firestore.Timestamp.fromDate(startDate)
    const res = firebase.shilumniIntakesGet(campus, startTs, courseType)

    res
      .then(res => {
        setFetching(false)
        setFetched(true)
        setIntakes(res)
      })
      .catch(err => {
        console.log('error', err)
        setFetching(false)
        setFetched(true)
        setErrors(err)
      })
  }

  useEffect(
    () => {
      if (
        firebase &&
        !fetching &&
        campus !== '' &&
        year !== '' &&
        courseType !== ''
      ) {
        fetchIntakes()
      }
    },
    [firebase, campus, year, courseType]
  )

  if (fetched && intakes) {
    const _startDate = parseInt(year)

    const filteredIntakes = filterByStartDateAndEndDate(
      intakes,
      _startDate,
      endDate
    )

    if (filteredIntakes.length === 0) {
      return <FormField>No intakes found</FormField>
    } else {
      return (
        <FormField width="100%">
          <IntakeSelect
            intakes={filteredIntakes}
            currIntake={currIntake}
            setCurrIntake={setCurrIntake}
            handleDataChange={handleDataChange}
          />
        </FormField>
      )
    }
  } else if (fetch && errors) {
    return <FormField>Errors</FormField>
  } else if (fetching) {
    return <FormField>Loading intakes...</FormField>
  } else {
    return (
      <FormField>Select from the options above to find your intake.</FormField>
    )
  }
}

export default SetupIntakeShilumniCourses

const StyledRadio = styled(Radio)`
  ${props => props.theme.materialRadio()}
`

const StyledFormControlLabel = styled(FormControlLabel)`
  ${props => props.theme.materialFormControlLabel()}
`

const IntakeSelect = ({
  intakes,
  currIntake,
  setCurrIntake,
  handleDataChange,
}) => {
  const options = intakes.map((intake, i) => {
    const { title, startDate, endDate } = intake.data()

    const dateFormat = 'MMM YY'
    const startDateFormatted = moment.unix(startDate.seconds).format(dateFormat)
    const endDateFormatted = moment.unix(endDate.seconds).format(dateFormat)
    const dateSpan = `(${startDateFormatted} - ${endDateFormatted})`

    return (
      <StyledFormControlLabel
        key={i}
        value={intake.id}
        control={<StyledRadio />}
        label={`${title} ${dateSpan}`}
      />
    )
  })

  const handleChange = event => {
    const { value } = event.target
    setCurrIntake(value)
    handleDataChange('intake', { id: value })
  }

  const value = currIntake || ''

  return (
    <FormControl component="fieldset" className="form-control">
      <RadioGroup
        aria-label="intake"
        name="intake"
        value={value}
        onChange={handleChange}
      >
        {options}
      </RadioGroup>
    </FormControl>
  )
}
