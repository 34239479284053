import React from 'react'
import styled from 'styled-components'
import { Flex, Box } from 'reflexbox/styled-components'

export default ({ currStep }) => {
  const steps = [
    <span key="step-text-1">
      <em>Choose </em>Plan
    </span>,
    <span key="step-text-2">
      <em>Find </em>Intake
    </span>,
    <span key="step-text-3">
      <em>Your </em>Details
    </span>,
    <span key="step-text-4">
      Confirm<em>ation</em>
    </span>,
  ]

  const stepContent = steps.map((step, index) => {
    const key = `step-${index}`

    const isActive = index + 1 <= currStep
    return (
      <Box width={[1 / 4, 1 / 4]} key={key} pr={`4px`}>
        <Step isActive={isActive}>{step}</Step>
      </Box>
    )
  })

  return (
    <Steps>
      <Flex flexWrap={['wrap']} width={`100%`} mb={[2, 0]}>
        {stepContent}
      </Flex>
    </Steps>
  )
}

const Steps = styled.div`
  width: 100%;
`

const Step = styled.button`
  margin-bottom: 3em;
  border-bottom: 3px solid
    ${props =>
      props.isActive
        ? props.theme.colors.highlight1
        : props.theme.colors.white};
  width: 100%;
  color: ${props => props.theme.colors.white};
  text-align: left;
  padding: 0 0 10px 0;
  em {
    font-style: normal;
  }
  @media screen and (max-width: ${props => props.theme.responsive.md}) {
    margin-bottom: 2em;
  }
  @media screen and (max-width: ${props => props.theme.responsive.sm}) {
    margin-bottom: 2em;
    em {
      display: none;
    }
  }
`
