import React, { useState } from 'react'
import { Wrap } from '../components/page/components'
import AccountSetupFormWrap from '../components/account/setup-form-wrap'
import SEO from '../components/SEO'
import { StripeProvider, Elements } from 'react-stripe-elements'
import { withFirebase } from '../components/firebase'
import FocusHeader from '../components/global/focus-header'
import FocusBody from '../components/page/focus-body'
import { currencyOptions } from '../utils/siteConfig'
import _ from 'lodash'

const stripeKeys = {
  US: process.env.GATSBY_STRIPE_PUBLIC_KEY_US,
  UK: process.env.GATSBY_STRIPE_PUBLIC_KEY_UK,
  AU: process.env.GATSBY_STRIPE_PUBLIC_KEY_AU,
}

const AccountSetup = ({ location, firebase }) => {
  const { href } = location

  const [currStripeKey, setCurrStripeKey] = useState(stripeKeys.US)
  const [country, setCountry] = useState('US')

  const changeCountry = e => {
    const currOption = _.find(currencyOptions, { currency: e.target.value })
    setCountry(currOption.country)
    setCurrStripeKey(stripeKeys[currOption.country])
  }

  return (
    <Wrap>
      <SEO title="Account Sign-up" />
      <FocusHeader title="Sign up" />
      <FocusBody>
        <StripeProvider apiKey={currStripeKey} key={currStripeKey}>
          <Elements>
            <AccountSetupFormWrap
              url={href}
              firebase={firebase}
              onChangeCountry={changeCountry}
              country={country}
              currencyOptions={currencyOptions}
            />
          </Elements>
        </StripeProvider>
      </FocusBody>
    </Wrap>
  )
}

export default withFirebase(AccountSetup)
