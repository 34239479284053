import React, { useEffect } from 'react'
import {
  FormBox,
  FormFields,
  FormField,
  FormError,
  FormTitle,
} from '../page/components'
import _ from 'lodash'
import { Formik } from 'formik'
import styled from 'styled-components'
import TextField from '@material-ui/core/TextField'
import { Flex, Box } from 'reflexbox'
import SetupPlanCc from './setup-plan-cc'

const StyledTextField = styled(TextField)`
  ${props => props.theme.materialTextField()}
`

const SetupUser = ({
  formData,
  submitting,
  handleDataChange,
  schema,
  stripe,
  stripeState,
  onStripePaymentMethod,
  isActive,
}) => {
  const saving = false

  return (
    <Wrap submitting={submitting} isActive={isActive}>
      <Form
        user={null}
        onSubmit={null}
        saving={saving}
        submitting={submitting}
        formData={formData}
        handleDataChange={handleDataChange}
        schema={schema}
      />
      <SetupPlanCc
        stripe={stripe}
        formData={formData}
        stripeState={stripeState}
        handleDataChange={handleDataChange}
        onStripePaymentMethod={onStripePaymentMethod}
        title="Payment details"
      />
    </Wrap>
  )
}

export default SetupUser

const Form = ({ formData, handleDataChange, schema }) => {
  // Send vals to parent
  const handleFieldChange = e => {
    const user = _.cloneDeep(formData.user) || {}
    user[e.target.name] = e.target.value
    handleDataChange('user', user)
  }

  // const timestamp = new Date().getTime()
  const initialValues = {
    fullName: ``,
    email: ``,
    password: '',
    passwordConfirm: '',
    stripeCC: false,
  }

  useEffect(() => {
    handleDataChange('user', initialValues)
  }, [])

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={() => {}}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <FormBox>
          <form onSubmit={handleSubmit}>
            <FormFields>
              <Flex flexWrap={['wrap']} width={`100%`} mb={[2, 0]}>
                <Box width={[1, 1]}>
                  <FormTitle>Your Details</FormTitle>
                </Box>
              </Flex>

              <Flex flexWrap={['wrap']} width={`100%`} mb={[2, 2]}>
                <Box width={[1, 6 / 12]}>
                  <FormField>
                    <StyledTextField
                      label="Full Name"
                      name="fullName"
                      onChange={e => {
                        handleFieldChange(e)
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      value={values.fullName}
                      margin="normal"
                    />
                    {errors.fullName && touched.fullName ? (
                      <FormError>{errors.fullName}</FormError>
                    ) : null}
                  </FormField>
                </Box>
                <Box width={[1, 6 / 12]}>
                  <FormField>
                    <StyledTextField
                      label="Email"
                      name="email"
                      onChange={e => {
                        handleFieldChange(e)
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      value={values.email}
                      margin="normal"
                    />
                    {errors.email && touched.email ? (
                      <FormError>{errors.email}</FormError>
                    ) : null}
                  </FormField>
                </Box>
              </Flex>
              <Flex flexWrap={['wrap']} width={`100%`} mb={[2, 2]}>
                <Box width={[1, 6 / 12]}>
                  <FormField>
                    <StyledTextField
                      label="Password"
                      name="password"
                      type="password"
                      onChange={e => {
                        handleFieldChange(e)
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      value={values.password}
                      margin="normal"
                    />
                    {errors.password && touched.password ? (
                      <FormError>{errors.password}</FormError>
                    ) : null}
                  </FormField>
                </Box>
                <Box width={[1, 6 / 12]}>
                  <FormField>
                    <StyledTextField
                      label="Password Confirm"
                      name="passwordConfirm"
                      type="password"
                      onChange={e => {
                        handleFieldChange(e)
                        handleChange(e)
                      }}
                      onBlur={handleBlur}
                      value={values.passwordConfirm}
                      margin="normal"
                    />
                    {errors.passwordConfirm && touched.passwordConfirm ? (
                      <FormError>{errors.passwordConfirm}</FormError>
                    ) : null}
                  </FormField>
                </Box>
              </Flex>
            </FormFields>
          </form>
        </FormBox>
      )}
    </Formik>
  )
}

const Wrap = styled.div`
  width: 100%;
  display: ${props => (props.isActive ? `block` : `none`)};
  opacity: ${props => (props.submitting ? `0.5` : `1`)};
`
