import React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Flex, Box } from 'reflexbox'
import { FormTitle, Copy } from '../page/components'

export default ({ isActive, formData }) => {
  //console.log('>>>', formData)

  const { user } = formData
  const { intake } = formData
  const { plan } = formData

  if (user && intake && plan) {
    return (
      <Wrap isActive={isActive}>
        <FormTitle>You're Ready!</FormTitle>
        <Flex flexWrap={['wrap']} width={`100%`} mb={[1, 4]}>
          <Box width={[1, 1]}>
            <Copy>
              <p>
                Woohoo. Your account has been setup and is ready to use. We’ve
                also sent a confirmation email.
              </p>
              <p>
                To get started please <Link to="/sign-in">sign-in here</Link>.
              </p>
            </Copy>
          </Box>
        </Flex>
      </Wrap>
    )
  } else {
    return null
  }
}

const Wrap = styled.div`
  width: 100%;
  display: ${props => (props.isActive ? `block` : `none`)};
`
