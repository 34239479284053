import React, { useState, useEffect } from 'react'
import * as Yup from 'yup'
import styled from 'styled-components'
import { injectStripe } from 'react-stripe-elements'
import { Flex, Box } from 'reflexbox'

import {
  FormBox,
  FormFields,
  Button,
  ButtonsWrap,
  Errors,
} from '../page/components'
import SetupIntakeShilumni from './setup-intake-shilumni'
import SetupPlan from './setup-plan'
import SetupUser from './setup-user'
import SetupSteps from './setup-steps'
import SetupReview from './setup-review'
import IconLock from '../svg/icon-lock'

const userSchema = Yup.object().shape({
  fullName: Yup.string().required('Please enter your full name.'),
  email: Yup.string()
    .email('Please enter a valid email address.')
    .required('Please enter your email address.'),
  password: Yup.string()
    .required('Please Enter your password')
    .test(
      'regex',
      'Password must be at least 8 characters, contain a symbol and a number character.',
      val => {
        let regExp = new RegExp('^(?=(.*\\d|.*?[#?!@$%^&*-]))(?=.*[a-z]).{8,}$')
        return regExp.test(val)
      }
    ),
  passwordConfirm: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Password confirm is required'),
  stripeCC: Yup.bool().oneOf(
    [true],
    'Please check or enter your credit card details.'
  ),
})

const intakeSchema = Yup.object().shape({
  id: Yup.string().required(
    'Please find then select the intake you were part of.'
  ),
})

const planSchema = Yup.object().shape({
  id: Yup.string().required('Please select a valid plan.'),
  name: Yup.string(),
  value: Yup.string(),
  coupon: Yup.string(),
})

const INITIAL_STRIPE_STATE = {
  fetching: false,
  fetched: false,
  payment: null,
  paymentError: null,
}

const _SetupFormWrap = ({
  url,
  firebase,
  stripe,
  onChangeCountry,
  country,
  currencyOptions,
}) => {
  const [submitting, setSubmitting] = useState()
  const [errors, setErrors] = useState([])
  const [formData, setFormData] = useState({})
  const [stripeState, setStripeState] = useState(INITIAL_STRIPE_STATE)
  const [currStep, setCurrStep] = useState(1)

  useEffect(
    () => {
      if (
        stripeState.fetched === true &&
        stripeState.fetching === false &&
        submitting === true
      ) {
        // If Stripe has fetched:
        // - Meaning createPaymentMethod called with CC details and response received.
        // - Then we can call the main submit
        onSubmit(true)
      }
    },
    [stripeState, submitting]
  )

  const urlObject = new URL(url)
  const paramInviteId = urlObject.searchParams.get('id')

  const handleDataChange = (key, value) => {
    setFormData(prev => {
      return {
        ...prev,
        [key]: value,
      }
    })
  }

  const onStripePaymentMethod = (paymentMethod, paymentMethodError) => {
    if (paymentMethodError) {
      setSubmitting(false)
      setErrors([`Payment Error → ${paymentMethodError.message}`])
    }
    setStripeState(prevState => ({
      ...prevState,
      fetched: true,
      fetching: false,
      payment: paymentMethod,
      paymentError: paymentMethodError,
    }))
  }

  const onBack = () => {
    setErrors([])
    setCurrStep(2)
  }

  const onSubmit = canContinue => {
    if (submitting && !canContinue) return

    setSubmitting(true)
    setErrors([])

    const userValid = userSchema.validate(formData.user)
    const intakeValid = intakeSchema.validate(formData.intake)
    const planValid = planSchema.validate(formData.plan)

    Promise.all([intakeValid, userValid, planValid])
      .then(() => {
        if (stripeState.fetched && stripeState.payment) {
          const userCreateFunc = firebase.functions.httpsCallable(
            'dbUserCreate'
          )
          const funcData = {
            ...formData,
            stripe: stripeState.payment,
            createdVia: 'signup-shilumni', // Hard code short term
            country: country,
          }

          console.log('SUBMITTING! ++++++ ', funcData)

          userCreateFunc(funcData)
            .then(res => {
              console.log('✔ Account created', res)

              const { data } = res
              const { status } = data
              const stripeResponse = data.stripe

              if (status === 'success') {
                if (stripeResponse.subscription.status === 'active') {
                  console.log('✔ Subscription created')
                  setCurrStep(4)
                } else if (
                  stripeResponse.subscription.status === 'incomplete'
                ) {
                  console.log('✘ Subscription needs auth')
                  const { subscription } = stripeResponse
                  const { latest_invoice } = subscription
                  const { payment_intent } = latest_invoice
                  if (payment_intent) {
                    const { client_secret, status } = payment_intent
                    if (
                      status === 'requires_action' ||
                      status === 'requires_payment_method'
                    ) {
                      stripe
                        .confirmCardPayment(client_secret)
                        .then(function(result) {
                          if (result.error) {
                            console.log('✘ Subscription auth failed')
                            setStripeState(INITIAL_STRIPE_STATE)
                            setSubmitting(false)
                            setErrors([result.error.message])
                          } else {
                            const { paymentIntent } = result
                            if (
                              paymentIntent &&
                              paymentIntent.status === 'succeeded'
                            ) {
                              console.log('✔ Subscription auth confirmed')
                              setCurrStep(4)
                            }
                          }
                        })
                    } else {
                      console.log('Subscription created ✔')
                      setCurrStep(4)
                    }
                  }
                }
              }
            })
            .catch(error => {
              const { message } = error
              setStripeState(INITIAL_STRIPE_STATE)
              setSubmitting(false)
              setErrors([`We had a problem submitting! ${message}`])
            })
        } else {
          setStripeState(prevState => ({
            ...prevState,
            fetched: false,
            fetching: true,
            payment: null,
            paymentError: null,
          }))
        }
      })
      .catch(err => {
        setErrors(err.errors)
        setSubmitting(false)
      })
  }

  let errorContent
  if (errors.length > 0) {
    errorContent = (
      <Flex flexWrap={['wrap']} width={`100%`} mb={[0]}>
        <Box width={[1, 1]}>
          <Errors>
            {errors.map((error, i) => {
              return <p key={`error-${i}`}>{error}</p>
            })}
          </Errors>
        </Box>
      </Flex>
    )
  }

  let submitContent
  if (currStep === 3) {
    const buttonText = submitting ? `Processing...` : `Confirm & Pay`

    submitContent = (
      <>
        {errorContent}
        <SubmitWrap isSubmitting={submitting}>
          <ButtonsWrap>
            <Button onClick={() => onBack()} style="back">
              Back
            </Button>
            <Button type="submit" onClick={() => onSubmit()}>
              <ButtonIcon>
                <IconLock />
              </ButtonIcon>{' '}
              {buttonText}
            </Button>
          </ButtonsWrap>
        </SubmitWrap>
        {/* <FormField marginRight={`50%`} marginBottom={`10px`}>
          <FormCopy>
            <p>
              Account already setup?
              <br />
              Sign in <Link to="/sign-in">here</Link>.
            </p>
          </FormCopy>
        </FormField> */}
      </>
    )
  }

  return (
    <FormBox>
      <FormFields>
        <SetupSteps currStep={currStep} setCurrStep={setCurrStep} />
        <SetupPlan
          country={country}
          currencyOptions={currencyOptions}
          firebase={firebase}
          formData={formData}
          handleDataChange={handleDataChange}
          isActive={currStep === 1}
          onChangeCountry={onChangeCountry}
          onStripePaymentMethod={onStripePaymentMethod}
          setCurrStep={setCurrStep}
          stripe={stripe}
          stripeState={stripeState}
          submitting={submitting}
        />
        <SetupIntakeShilumni
          firebase={firebase}
          formData={formData}
          handleDataChange={handleDataChange}
          inviteId={paramInviteId}
          isActive={currStep === 2}
          schema={intakeSchema}
          setCurrStep={setCurrStep}
          submitting={submitting}
        />
        <SetupUser
          formData={formData}
          handleDataChange={handleDataChange}
          isActive={currStep === 3}
          onStripePaymentMethod={onStripePaymentMethod}
          setCurrStep={setCurrStep}
          schema={userSchema}
          stripe={stripe}
          stripeState={stripeState}
          submitting={submitting}
        />
        <SetupReview
          formData={formData}
          isActive={currStep === 4}
          setCurrStep={setCurrStep}
        />
        {submitContent}
      </FormFields>
    </FormBox>
  )
}

const SetupFormWrap = injectStripe(_SetupFormWrap)
export default SetupFormWrap

const ButtonIcon = styled.span`
  display: inline-block;
  margin-right: 10px;
`

const SubmitWrap = styled.div`
  margin-top: 2em;
  opacity: ${props => (props.isSubmitting ? `0.5` : `1.0`)};
`
