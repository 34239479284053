import React, { useState } from 'react'
import {
  FormField,
  FormTitle,
  Button,
  ButtonsWrap,
  Errors,
} from '../page/components'
import styled from 'styled-components'
// import MenuItem from '@material-ui/core/MenuItem'
import NativeSelect from '@material-ui/core/NativeSelect'
import FormControl from '@material-ui/core/FormControl'
import { campuses, courseTypes } from '../../utils/siteConfig'

import Courses from './setup-intake-shilumni-courses'

const INITIAL_VALUES = {
  campus: '',
  year: '',
  courseType: '',
}

const StyledFormControl = styled(FormControl)`
  ${props => props.theme.materialFormControlSelect()};
`

const StyledSelect = styled(NativeSelect)`
  ${props => props.theme.materialSelect()};
`

const SetupIntakeShilumni = ({
  firebase,
  submitting,
  handleDataChange,
  formData,
  isActive,
  schema,
  setCurrStep,
}) => {
  const [values, setValues] = useState(INITIAL_VALUES)
  const [errors, setErrors] = useState([])

  const handleChange = event => {
    const { name, value } = event.target
    setValues(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const onBack = () => {
    setErrors([])
    setCurrStep(1)
  }

  const onContinue = () => {
    schema
      .validate(formData.intake)
      .then(() => {
        setErrors([])
        setCurrStep(3)
      })
      .catch(function(err) {
        setErrors(err.errors)
      })
  }

  const { campus, year, courseType } = values

  const campusVal = campus
  const campusOptions = campuses.map((campus, i) => {
    return (
      <option key={i} value={campus}>
        {campus}
      </option>
    )
  })
  campusOptions.unshift(
    <option key="default" value="">
      Campus
    </option>
  )

  const yearVal = year
  const startYear = 2006
  const endYear = new Date().getFullYear() + 1
  const yearOptions = []
  for (let index = startYear; index < endYear; index++) {
    yearOptions.push(
      <option key={index} value={index}>
        {index}
      </option>
    )
  }
  yearOptions.unshift(
    <option key="2005" value="2005">
      1997-2005
    </option>
  )
  yearOptions.unshift(
    <option key="default" value="">
      Year
    </option>
  )

  const typeVal = courseType
  const typeOptions = courseTypes.map((courseType, i) => {
    return (
      <option key={i} value={courseType}>
        {courseType}
      </option>
    )
  })
  typeOptions.unshift(
    <option key="default" value="">
      Type
    </option>
  )

  let errorContent
  if (errors.length > 0) {
    errorContent = (
      <Errors>
        {errors.map((e, index) => (
          <p key={`erorr-${index}`}>{e}</p>
        ))}
      </Errors>
    )
  }

  return (
    <Wrap submitting={submitting} isActive={isActive}>
      <FormTitle>Find Intake</FormTitle>
      {/* <FormField width="100%">
        <p>Find and select your intake</p>
      </FormField> */}
      <Drops>
        <FormField width={`${(3 / 8) * 100}%`}>
          <StyledFormControl>
            <StyledSelect
              name="campus"
              value={campusVal}
              onChange={handleChange}
            >
              {campusOptions}
            </StyledSelect>
          </StyledFormControl>
        </FormField>
        <FormField width={`${(3 / 8) * 100}%`}>
          <StyledFormControl>
            <StyledSelect
              name="courseType"
              value={typeVal}
              onChange={handleChange}
            >
              {typeOptions}
            </StyledSelect>
          </StyledFormControl>
        </FormField>
        <FormField width={`${(2 / 8) * 100}%`}>
          <StyledFormControl>
            <StyledSelect name="year" value={yearVal} onChange={handleChange}>
              {yearOptions}
            </StyledSelect>
          </StyledFormControl>
        </FormField>
      </Drops>

      <Courses
        firebase={firebase}
        values={values}
        handleDataChange={handleDataChange}
      />

      {errorContent}

      <FormField marginRight={`50%`}>
        <ButtonsWrap>
          <Button onClick={() => onBack()} style="back">
            Back
          </Button>
          <Button onClick={() => onContinue()}>Continue</Button>
        </ButtonsWrap>
      </FormField>
    </Wrap>
  )
}

export default SetupIntakeShilumni

const Wrap = styled.div`
  width: 100%;
  display: ${props => (props.isActive ? `block` : `none`)};
  opacity: ${props => (props.submitting ? `0.5` : `1`)};
`

const Drops = styled.div`
  display: flex;
`
